import React, { FC } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { Link } from "react-router-dom"
import HeaderFilter3 from "./HeaderFilter3"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineSecond4 = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {
  const filteredPosts = posts.filter((item) => {
    return item.subcategory_id === subcategory_id && item.category_id === category_id
  })
  return (
    <div className={`nc-SectionMagazine4 ${className}`}>
      <div className="mobile-header"><HeaderFilter3 heading={heading} /></div>
      <button className="subcategory-btn" style={{backgroundColor:'red', fontSize:"20px", color:"white", marginTop:"10px", marginBottom:'10px', width:"42%", border:"none", padding:"3px 3px 3px 3px"}}>Polityka na świecie</button>
      {/* <hr></hr> */}

      {!posts?.length && <span>Nothing we found!</span>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8">
      {/* {posts[30] && <Card8 category={category} className="sm:col-span-2" post={posts[30]} />} */}
        {subcategory_id !== null &&
        filteredPosts
        .filter((item) => item.type == "image")
        .slice(filteredPosts.length - 6,filteredPosts.length)
        .reverse()
          .map((item, index) => (
            <div key={item.id} style={{display:"grid", alignContent: 'space-between'}}>
                <div style={{display:'flex', flexFlow:"column", gap:"10px"}}>
                <Link to={`/${item?.title.toLowerCase()}/${item?.name === "Świat" ? "swiat" : item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`} style={{color:"black"}}><h3 className="mobile-font"><b>{item?.article_title}</b></h3></Link>
                <span className="category-titles" style={{color:"red",fontWeight:700}}>{item?.title}</span>
                <p className="article-descy" style={{textAlign:'justify'}}>{item?.description.slice(0,80)}....</p>
                </div>
            <Card9 key={index} post={item} />
            <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
            </div>
          ))}
        {/* {category_id === 0 && subcategory_id === 0  && posts[5] && <Card8 className="sm:col-span-2" post={posts[5]} />} */}
      </div>
    </div>
  );
};

export default SectionMagazineSecond4;
