import React, { FC, useState } from "react";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import SwitchDarkMode from "components/SwitchDarkMode/SwitchDarkMode";
import SearchModal from "./SearchModal";
import ReactAnimatedWeather from 'react-animated-weather';
import Button from "../Button/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import LangDropdown from "./LangDropdown"
import LogoSvgMobile from "components/Logo/LogoSvg_mobiile"
import LogoSvg from "components/Logo/LogoSvg"
import LogoMobile from "components/Logo/Logo_mobile"
import { BACKEND_URL } from "constants"

// export interface MainNav1Props {}

const defaults = {
  icon: 'CLEAR_DAY',
  color: 'goldenrod',
  size: 32,
  animate: true
};

const TextButton = ({ onClick, label, activeCategory, style = {} }) => {
  return (
    <button style={{ border: 'none', padding: 0, fontWeight: label === activeCategory ? 700 : 400, ...style}} onClick={onClick}>{label}</button>
  )
} 


const MainNav1 = () => {
  const [hidden,setHidden] = useState(false)
  // const [show,setShow] = useState(false)
  const params = useParams()
  const { pathname } = useLocation();
  const parts = pathname.substring(1).split('/');
  // console.log('parts: ', parts);
  let { category, subcategory, categoryid,  subcategoryid } = params;
  if (!category) {
    category = parts.length === 2 ? parts[0] : parts[1];
  }

  if (!categoryid) {
    categoryid = parts.length === 2 ? parts[1] : parts[2];
  }

  // const [category, setCategory] = useState(params.category || '')
  // const [subcategory, setSubCategory] = useState(params.subcategory || '')
  // const [categoryid, setCategoryId] = useState(params.categoryid || 1)
  // const [subcategoryid, setSubCategoryId] = useState(params.subcategoryid || 1);
  // console.log('pathname: ', category);

  const paths = window.location.pathname.split('/').slice(1);
  return (
    <>
    <div className="mobile-logos"><Logo/></div>
    <div className="nc-MainNav1 relative z-10 bg-white dark:bg-slate-900 ">
      <div
       className="container"
       >
        <div style={{flexFlow:"column"}} className="flex items-center mobiles-navigation">
          <div className="menu-responsive">
          <div className="flex items-center lg:hidden">
            <MenuBar />
          </div>
          <div className="flex items-center space-x-4 sm:space-x-10 2xl:space-x-14 mobile-menu">
            <LogoMobile/>
          </div>
          <div className="mobile-nav">
          <Link className="link-font" style={{ fontWeight: paths.includes("swiat") ? 700 : 200, color:  paths.includes("swiat") ? "#ac7c08" : "black" ,padding:"4px 10px 4px 10px", }} to={`swiat/1`}>Ś W I A T</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("kraj") ? 700 : 200, color:  paths.includes("kraj") ? "#ac7c08" : "black", padding:"4px 10px 4px 10px" }} to={`kraj/3`}>K R A J</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("region") ? 700 : 200, color:  paths.includes("region") ? "#ac7c08" : "black", padding:"4px 10px 4px 10px" }} to={`region/5`}>R E G I O N</Link>
         <div className="additional-menu">
         <Link className="link-font" to="/horoskop">H O R O S K OP</Link>
         <Link className="link-font" to="/kuchnia">K U C H N I A</Link>  
         <Link className="link-font" to="/pogoda">P O G O D A</Link>
         </div> 
          </div>
          <div className="mobile-logo-down"><Logo/></div>
          <div className="search-responsive">
            <SearchModal/>
           </div> 
          </div>
          <div className="mobile-links">
           <Link className="link-font" style={{ fontWeight: paths.includes("swiat") ? 900 : 200, color:  paths.includes("swiat") ? "#ac7c08" : "black", padding:"4px 10px 4px 10px"}} to={`gospodarka/swiat/1/1`}>Ś W I A T</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("kraj") ? 900 : 200, color:  paths.includes("kraj") ? "#ac7c08" : "black", padding:"4px 10px 4px 10px", }} to={`polityka/kraj/3/2`}>K R A J</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("region") ? 900 : 200, color:  paths.includes("region") ? "#ac7c08" : "black", padding:"4px 10px 4px 10px" }} to={`społeczeństwo/region/5/3`}>R E G I O N</Link>
           </div>
          <div className="hidden-nav" style={{position:"absolute", height:"24px", width:"100%", justifyContent:"center", color:"black", backgroundColor:"white", right:"1px", gap:"25px", alignItems:"center"}}>
                { category !== undefined && <div className="animation-nav" style={{backgroundColor:'white', width:"100%", display:'flex', justifyContent:"center", color:"black", gap:"20px", alignItems:"center"}}>
                <Link style={{color: parts[0] === "polityka" ? "#ac8502": "black" }} 
                to={`/polityka/${category}/${categoryid}/2` }
         >P O L I T Y KA</Link>
         <Link style={{color: parts[0] === "gospodarka" ? "#ac8502": "black" }} 
                to={`/gospodarka/${category}/${categoryid}/1`}>G O S P O D A R K A</Link>

          <Link style={{color: parts[0] === "spoleczenstwo" ? "#ac8502": "black" }} 
                to={`/spoleczenstwo/${category}/${categoryid}/3`}>S P O Ł E C Z E Ń S T W O</Link>

          <Link style={{color: parts[0] === "sport" ? "#ac8502": "black" }} 
                to={`/sport/${category}/${categoryid}/4`}>S P O R T</Link>

                  {window.location.pathname.split("/")[parts.length === 2 ? 1: 2]  == "region" && <Link style={{color: parts[0] === "aktualnosci" ? "rgb(172, 133, 2)": "black" }} 
                to={`/aktualnosci/${category}/${categoryid}/5`}>A K T U A L N O Ś C I</Link>}
                </div>}
          </div>
          {/* </div> */}
          </div>
        </div>
      </div>
      </>
  );
};

export default MainNav1;
