import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import Card4 from "components/Card4/Card4";
import Card7 from "components/Card7/Card7";
import { PostDataType } from "data/types";
import Card9 from "components/Card9/Card9";
import Card10 from "components/Card10/Card10";
import Card11 from "components/Card11/Card11";
import Card10V2 from "components/Card10/Card10V2";
import MySlider from "components/MySlider";
import { Link } from "react-router-dom"

// export interface SectionSliderPostsProps {
//   className?: string;
//   heading: string;
//   subHeading?: string;
//   posts: PostDataType[];
//   postCardName?: "card4" | "card7" | "card9" | "card10" | "card10V2" | "card11";
//   perView?: 2 | 3 | 4;
// }

const SectionSliderPosts = ({
  heading,
  subHeading,
  className = "",
  posts,
  postCardName = "card4",
  perView = 4,
}) => {
  let CardComponent = Card4;

  // switch (postCardName) {
  //   case "card4":
  //     CardComponent = Card4;
  //     break;
  //   case "card7":
  //     CardComponent = Card7;
  //     break;
  //   case "card9":
  //     CardComponent = Card9;
  //     break;
  //   case "card10":
  //     CardComponent = Card10;
  //     break;
  //   case "card10V2":
  //     CardComponent = Card10V2;
  //     break;
  //   case "card11":
  //     CardComponent = Card11;
  //     break;

  //   default:
  //     break;
  // }

  return (
    <div style={{paddingBottom:"3rem", paddingTop:"2rem"}} className={`nc-SectionSliderPosts ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>

      <MySlider
        data={posts}
        renderItem={(item, indx) => {
          return(
            <div key={item.id} style={{display:"flex", flexFlow:'column', gap:"10px", justifyContent: "space-between"}}>
            <div style={{display:'flex', flexFlow:"column", gap:"10px"}}>
            <button onClick={() => window.location.href=`/${item?.title.toLowerCase()}/${item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`} style={{color:"black",maxWidth:"324px"}} className="line-clamp-3" title={item?.article_title}>
            <h3 style={{textAlign:"left"}}><b>{item?.article_title}</b></h3>
            </button>
            <span style={{color:"red",fontWeight:700}}>{item?.title}</span>
            <p>{item?.long_description.slice(0,60)}....</p>
            </div>
            <CardComponent post={item} />
        </div>
          )
      }}
        itemPerRow={perView}
      />
    </div>
  );
};

export default SectionSliderPosts;
