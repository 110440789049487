import React, { useEffect, useRef, useState } from "react";
import pdf from "./ResPublica grudzien swiateczny_241219_105957.pdf"

const PageGazetaSanta = () => {
  useEffect(() => {
    window.location.href = pdf
  })
};

export default PageGazetaSanta
