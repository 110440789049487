import React, { FC } from "react";
import NcImage from "components/NcImage/NcImageAds";
import { TaxonomyType } from "data/types";
import Link from "components/Link";


const CardCategory1 = ({
  className = "",
  size = "normal",
  id,
  type,
  video,
  category,
  category_id,
  subcategory_id,
  subcategory,
  image,
  name,
}) => {
  return (
    <Link
      href={`/${subcategory}/${category.toLowerCase()}/${category_id}/${subcategory_id}/artykuł/${id}`} title={name}
      className={`nc-CardCategory1 flex items-center ${className}`}
    >
      {image == undefined && <span></span>}
      {type == "image" && image != undefined &&
      <NcImage
        alt=""
        containerClassName={`relative flex-shrink-0  overflow-hidden` }
        src={image}
        // fill
        // className="object-cover"
        sizes="380px"
      />}
        {type == "wideo" && <video src={video} poster={image} controls width={1000} height={900} />}
      <div>
      <Link style={{color:'black'}} href={`/${subcategory}/${category.toLowerCase()}/${category_id}/${subcategory_id}/artykuł/${id}`} title={name}
    >
        <h2
        style={{fontSize:"1rem", fontWeight:'bold', maxWidth:'300px', lineHeight:"15px"}}
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold text-hover`}
        >
          {name}
        </h2>
        </Link>
        <span 
          className={`${
            size === "large" ? "text-sm" : "text-xs"
          } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
          {category}
        </span>
      </div>
    </Link>
  );
};

export default CardCategory1;
