import React, { useState, Fragment, useEffect } from "react";
import { Transition } from "app/headlessui";
import NavMobile from "components/Navigation/NavMobile";
import usePathname from "hooks/usePathname";
import { Link, useParams } from "react-router-dom"
import SearchModal from "../Header/SearchModal";

const MenuBar = () => {
  const [isVisable, setIsVisable] = useState(false);
  const pathname = usePathname();

  const params = useParams()

  const parts = pathname.substring(1).split('/');

  let { category, subcategory, categoryid,  subcategoryid } = params;
  if (!category) {
    category = parts.length === 2 ? parts[0] : parts[1];
  }

  if (!categoryid) {
    categoryid = parts.length === 2 ? parts[1] : parts[2];
  }

  const paths = window.location.pathname.split('/').slice(1);

  useEffect(() => {
    setIsVisable(false);
  }, [pathname]);

  const handleCloseMenu = () => setIsVisable(false);

  const renderContent = () => {
    return (
      <Transition show={isVisable} as={Fragment}>
        <div className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-neutral-900 bg-opacity-50 "
              onClick={handleCloseMenu}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition duration-100 transform"
            enterFrom="opacity-0 -translate-x-14"
            enterTo="opacity-100 translate-x-0"
            leave="transition duration-150 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-14"
          >
            <div className="fixed inset-y-0 left-0 w-screen max-w-sm overflow-y-auto z-50">
              <div className="flex min-h-full">
                <div className="w-full max-w-sm overflow-hidden transition-all">
                  <NavMobile onClickClose={handleCloseMenu} />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    );
  };

  return (
    <div style={{display:'flex', gap:"20px", alignItems:'baseline'}}>
      <button
        onClick={() => {
          setIsVisable(!isVisable);
        }}
        className="rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {/* <div className="search-responsive">
           <Link to="/login"><i style={{fontSize:"20px"}} class="fa fa-user" aria-hidden="true"></i></Link>
            <SearchModal/>
           </div> */}
      {/* <div>
      <div style={{display:"flex", alignItems:"center", gap:"0px"}}>
        <Link className="link-font" style={{ fontSize:"10px",fontWeight: paths.includes("swiat") ? 700 : 400, padding:"4px 10px 4px 10px",  backgroundColor:  paths.includes("swiat") && '#ac8502' }} to={`swiat/1`}>ŚWIAT</Link>
        <Link className="link-font" style={{ fontSize:"10px", fontWeight: paths.includes("kraj") ? 700 : 400, padding:"4px 10px 4px 10px", backgroundColor:  paths.includes("kraj") && '#ac8502' }} to={`kraj/3`}>KRAJ</Link>
        <Link className="link-font" style={{ fontSize:"10px", fontWeight: paths.includes("region") ? 700 : 400, padding:"4px 10px 4px 10px", backgroundColor:  paths.includes("region") && '#ac8502' }} to={`region/5`}>REGION</Link>
        <Link style={{fontSize:"10px", padding:"4px 10px 4px 10px"}} className="link-font" to="/horoskop">HOROSKOP</Link>
        <Link style={{fontSize:"10px", padding:"4px 10px 4px 10px"}} className="link-font" to="/kuchnia">KUCHNIA</Link>  
        <Link style={{fontSize:"10px", padding:"4px 10px 4px 10px"}} className="link-font" to="/pogoda">POGODA</Link>  
      </div>
      </div> */}
      {renderContent()}
    </div>
  );
};

export default MenuBar;
