import React, { FC, useEffect, useState } from "react";
import Tag from "components/Tag/Tag";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { TaxonomyType } from "data/types";
import { DEMO_TAGS } from "data/taxonomies";
import { BACKEND_URL } from "../../constants"
import { Link } from "react-router-dom"

const tagsDemo = DEMO_TAGS.filter((_, i) => i < 9);

const WidgetTags = () => {
  const [subcategories, setSubCategories] = useState([])
  const [id, setId] = useState(0)

  useEffect(() => {
    // if (!initialized.current) {
    //   initialized.current = true;
      fetch(`${BACKEND_URL}/subcategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setSubCategories(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);

  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden `}>
      {/* <WidgetHeading1
        title="Wszystkie kategorie"
        viewAll={{ label: "View all", href: "/#" }}
      /> */}
      {/* <Tag onclick={(e) => setId(e.target.id)} id={tag.id} className="mr-2 mb-2" key={tag.id} tag={tag} /> */}
      <div className="flex flex-wrap p-4 xl:p-5">
        {subcategories.map((tag) => (
          <div>
            <Link
      className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900mr-2 mb-2`}
      href={tag.href}
    >
      {`${tag.title}`}
      {/* {!hideCount && (
        <span className="text-xs font-normal">{tag.count}</span>
      )} */}
    </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetTags;
