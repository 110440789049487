import React, { HTMLAttributes } from "react";

export interface Heading2Props extends HTMLAttributes<HTMLHeadingElement> {
  emoji?: string;
}

const Heading5: React.FC<Heading2Props> = ({
  children,
  emoji = "",
  className = "justify-center",
  ...args
}) => {
  return (
    <div 
    style={{marginBottom:"4rem", marginTop:'2rem'}}
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className="desktop-container"
      >
        <hr className="line-left" style={{padding:"2px", width:"29%", justifyContent:"flex-start", backgroundColor:"#ac8502"}}></hr>
        <h2
          className={`font-mobile font-semibold`}
          {...args}
        >
          {children || `Section Heading`}
        </h2>
      {<hr className="line-right" style={{padding:"2px", width:"29%", backgroundColor:"#ac8502"}}></hr>} 
        {/* {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )} */}
      </div>
    </div>
  );
};

export default Heading5;
