import React from "react";
import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { Link } from "react-router-dom"
import LogoFooter from "components/Logo/LogoFooter"


const widgetMenus = [
  {
    id: "5",
    title: "Menu",
    menus: [
      { href: "/about", label: "O nas" },
      { href: "/rodo", label: "Polityka prywatności" },
      { href: "/contact", label: "Kontakt" },
      { href: "/regulamin", label: "Regulamin" },
      // { href: "/", label: "Pomoc" },
    ],
  },
  {
    id: "1",
    title: "Kategorie",
    menus: [
      { href: "/kuchnia", label: "Kuchnia regionalna" },
      { href: "/horoskop", label: "Horoskop" },
    ],
  },
];

const Footer = () => {
  const renderWidgetMenuItem = (menu, index) => {
    return (
      <div key={menu.id} className="text-sm">
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li style={{color:"white"}} key={index}>
              <a
                key={index}
                className="dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div style={{backgroundColor:"black"}} className="nc-Footer relative py-16 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5  ">
        <div className="grid col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <LogoFooter />
          </div>
          <div style={{display:"flex", gap:"20px"}}>{widgetMenus.map(renderWidgetMenuItem)}</div>
        </div>
        <div style={{marginTop:"10px"}} className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
      </div>
    </div>
  );
};

export default Footer;
