import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, useLocation, Routes } from "react-router-dom";
import { Page } from "./types";
import Footer from "../components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import PageAuthor from "app/author/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageSpecial from "app/(singles)/(default)/single_special/page";
import PageSingleTemplate2 from "app/(singles)/(default)/single-2/page";
import PageSingleTemplate3 from "app/(singles)/(has-sidebar)/single-3/page";
import PageSingleGallery from "app/(singles)/(default)/single-gallery/page";
import PageSingleAudio from "app/(singles)/(default)/single-audio/page";
import PageSingleVideo from "app/(singles)/(default)/single-video/page";
import PageSearch from "app/(search)/search/page";
import PageArticles from "app/(articles)/add-articles/page";
import PageGetArticles from "app/(articles)/get-articles/page";
import PageGetAllArticles from "app/(articles)/get-all-articles/page";
import PageEditArticles from "app/(articles)/edit-articles/page";
import PageSearchV2 from "app/(search)/search-2/page";
import PageAbout from "app/about/page";
import Page404 from "app/not-found";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import PageAddAccount from "app/(others)/account/page";
import PageForgotPass from "app/(others)/forgot-pass/page";
import PageSubcription from "app/(others)/subscription/page";
import image from "../images/respublica_baner.jpg"
import adsImage from "../images/image.png"
// import PageHomeDemo2 from "app/(home)/home-2/page";
// import PageHomeDemo3 from "app/(home)/home-3/page";
// import PageHomeDemo4 from "app/(home)/home-4/page";
import PageHomeDemo6 from "app/(home)/home-6/page";
import SiteHeader from "app/SiteHeader";
import cogoToast from "cogo-toast"
import PageSubCategory from "app/(archives)/category/subcategory"
import NotFound from "app/(archives)/NotFound/NotFound"
import PageHoroskop from "app/(archives)/horoskop/page"
import PageContact from "app/(others)/contact/page"
import {
  AdvertisingProvider,
  AdvertisingSlot,
  logVersionInfo
} from 'react-advertising';
import PageKuchnia from "app/(archives)/kuchnia/page"
import PageRegulamin from "app/(archives)/regulamin/page"
import PageRodo from "app/(archives)/rodo/page"
import PagePogoda from "app/pogoda/page"
import PageGazetaDecember from "app/(archives)/gazeta-grudzień/page"
import PageGazetaSanta from "app/(archives)/gazeta-świąteczna/page"
import PageGazetaNovember from "app/(archives)/gazeta-listopad/page"
import PageGazetaOctoberFirst from "app/(archives)/gazeta-pażdziernik-second/page"
import PageGazetaOctoberSecond from "app/(archives)/gazeta-pażdziernik-first/page"
import PageArticlesSection from "app/(articles)/articles-section/page"



let user = sessionStorage.getItem("user")


const onPermission = () => {
  cogoToast.error("Nie masz uprawnień do tych funkcji")
}


export const pages = [
  
  { path: "/", component: PageHomeDemo6 },
  { path: "/horoskop", component: PageHoroskop },
  { path: "/ResPublica grudzien swiateczny", component: PageGazetaSanta },
  { path: "/ResPublica grudzien", component: PageGazetaDecember },
  { path: "/ResPublica listopad", component: PageGazetaNovember },
  { path: "/ResPublica pażdziernika cześć 2", component: PageGazetaOctoberFirst },
  { path: "/ResPublica pażdziernika cześć 1", component: PageGazetaOctoberSecond },
  { path: "/kuchnia", component: PageKuchnia },
  { path: "/:subcategory/:category/:subcategory_id/:category_id", component: PageSubCategory },
  { path: "/:category/:category_id", component: PageHomeDemo6 },
  { path: "/:subcategory/:subcategory_id", component: PageHomeDemo6 },
  { path: "/author", component: PageAuthor },

  // single page -------------------------------------------------------
  { path: "/:subcategory/:category/:subcategory_id/:category_id/artykuł/:id", component: PageSingle },
  { path: "/artykuł/:id", component: PageSpecial },

   // articles -------------------------------------------------------
   { path: "/add-articles", component: PageArticles },
   { path: "/get-articles", component: PageGetArticles }, 
   { path: "/get-all-articles", component: PageGetAllArticles },
   { path: "/articlesinHome", component: PageArticlesSection },
   { path: "/edit-articles/:id", component: PageEditArticles }, 

  // other pages -------------------------------------------------------
  { path: "/about", component: PageAbout },
  { path: "/regulamin", component: PageRegulamin },
  { path: "/pogoda", component: PagePogoda },
  { path: "/rodo", component: PageRodo },
  { path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 },
  { path: "/login", component: PageLogin },
  { path: '*' , component:NotFound},
  // { path: "/signup", component: PageSignUp },
  { path: "/add-account", component: PageAddAccount },
]

const MyRoutes = () => {
  const[extended, setExtended] = useState(false)
  const[option, setOption] = useState("Rozwiń")

  return (
    <BrowserRouter>
    <div 
    // className="relative aspect-w-16 aspect-h-3 lg:aspect-h-3 xl:aspect-h-4 2xl:aspect-h-4 overflow-hidden z-0"
    >
    {/* <a href="https://www.facebook.com/people/Optyk-Szczepanik/100051593446346/"> */}
    <AdvertisingProvider>
    <div className="advert-container">
    <div className="image-top">
    <div style={{display:'flex',justifyContent:"flex-start", paddingLeft:"35px", gap:"8px", paddingTop:"122px", alignItems:"center"}}>
    </div>
    </div>
  </div>
    </AdvertisingProvider>
    {/* </a> */}
      {/* <NcImage
          alt=""
          containerClassName="absolute inset-0 overflow-hidden z-0"
          src="https://adv.adview.pl/ads/uploader/images/2024/8/7/b1230e0c-f8aa-45f8-afcb-eaba9a82bcff/image.png"
          fill
          sizes="(max-width: 1024px) 100vw, 1280px"
        /> */}
        </div>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
      <MusicPlayer />
    </BrowserRouter>
  );
};

export default MyRoutes;
