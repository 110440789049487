import YouTube from 'react-youtube';

const WidgetVideo = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories,
  height,
  width,
  posts,
}) => {
  const opts = {
    height: height,
    width: width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    // <div
    //   className={`nc-WidgetCategories overflow-hidden ${className}`}
    // >
      <div className="flow-root">
        {/* <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700"> */}
          {/* <div className="video-box"> */}
              {/* <button style={{marginBottom:'20px'}}>Strefa oglądania i czytania</button> */}
            <h3 style={{fontSize:"24px", marginBottom:'15px', fontWeight:'bold', textAlign:'center'}}>Rozmowa z dr. Markiem Szymkowiczem Zastępcą Dyrektora do spraw Medycznych Szpitala Powiatowego w Nysie</h3>
            <video style={{height:"230px"}} poster="https://marekxd.publit.io/file/w_1280/Sz.v2.jpg" controls src="https://marekxd.publit.io/file/h_480/Sz.v2.mp4" opts={opts}></video>
          {/* </div> */}
        </div>
  );
};

export default WidgetVideo;