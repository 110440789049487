import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import Card8Special from "components/Card8Special/Card8Special"
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineFirst = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {
  
  const filtered = posts.filter((post) => post.category_id == 5 && post.section_id == 1)
    .sort(() => function(a, b){return a - b})

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

  //   const postWithStatus2 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 2);

  return (
      <div style={{display:"flex", flexFlow:"column", gap:"20px"}} className={`nc-SectionMagazine4 ${className}`}>
         <button className="subcategory-btn" style={{backgroundColor:'red', fontSize:'20px', color:"white", width:"38%", border:"none", padding:"3px 3px 3px 3px"}}>Nysa i okolice</button>
         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 md:gap-8">
        {filtered
        .slice(filtered.length - 4, filtered.length)
        .reverse()
        .map((item) => {
          return(
                  <div key={item.id} style={{display:"flex", flexFlow:"column", justifyContent:"space-between"}}>
                  <div style={{display:"flex", flexFlow:'column', gap:"20px"}}>
                  <Link className="article-titles" to={`/${item?.title.toLowerCase()}/${item?.name === "Świat" ? "swiat" : item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`}>
                  <h3 className="mobile-font">{item?.article_title}</h3>
                  </Link>
                  <span className="category-titles" style={{color:"red",fontWeight:700}}><b>{item?.title}</b></span>
                  <p className="article-descy" style={{textAlign: "justify"}}>{item?.long_description.slice(0,80)}...</p>
                  </div>
                <Card8Special category={category} title={item?.title.toLowerCase()} name={item?.name.toLowerCase()} category_id={item?.category_id} subcategory_id={item?.subcategory_id} className="sm:col-span-2" post={item} />
                <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
                </div>
          )})}
          </div>
  
        {!posts?.length && <span>Nie znaleziono artykułów!</span>}
      </div>
    );
};

export default SectionMagazineFirst;
