import React, { useEffect, useRef, useState } from "react";
import SectionMagazine10 from "components/Sections/SectionMagazine10";
import { BACKEND_URL } from "../../../constants";
import logo from "../../../images/logo.png"
import SectionMagazine2 from "components/Sections/SectionMagazine2";
import SectionLatestPosts from "components/Sections/SectionLatestPosts";
import NcImage from "components/NcImage/NcImage"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SectionSliderPosts from "components/Sections/SectionSliderPosts"
import SectionMagazine4 from "components/Sections/SectionMagazine4"
import SectionMagazine8 from "components/Sections/SectionMagazine8"
import SectionGridPosts from "components/Sections/SectionGridPosts"
import banner from "../../../images/home-banner.jpg"
import SectionMagazineSecond4 from "components/Sections/SectionMagazineSecond4"
import DashboardSubmitPost from "app/(others)/dashboard/submit-post/page"
import SectionVideos from "components/Sections/SectionVideos"
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors"
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
// import ReactWeather, { useOpenWeather } from 'react-open-weather';
import PageContact from "app/(others)/contact/page"
import { Link } from "react-router-dom"
import { BrowserView } from "react-device-detect"
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  AdvertisingProvider,
  AdvertisingSlot,
  logVersionInfo
} from 'react-advertising';
import SectionMagazineFirst from "components/Sections/SectionMagazineFirst"
import WidgetCategoriesFirst from "components/WidgetCategoriesFirst/WidgetCategoriesFirst"
import SectionMagazineThird from "components/Sections/SectionMagazineThird"
import WidgetCategoriesSecond from "components/WidgetCategoriesSecond/WidgetCategoriesSecond"
import SectionMagazineSecond5 from "components/Sections/SectionMagazineSecond5"
import SectionMagazineFirstSpecial from "components/Sections/SectionMagazineFirstSpecial"
import WidgetCategoriesThird from "components/WidgetCategoriestThird/WidgetCategoriesThird"
import WidgetHeaderCategories from "components/WidgetHeaderCategories/WidgetHeaderCategories"
import cogoToast from "cogo-toast"
import {
  DEMO_POSTS,
  DEMO_POSTS_NEWS,
  DEMO_POSTS_VIDEO,
} from "data/posts";
import WidgetVideo from "components/WidgetVideo/WidgetVideo"
import HeaderFilter from "components/Sections/HeaderFilter"
import SectionMagazine1 from "components/Sections/SectionMagazine1"

//
// const MAGAZINE2_POSTS = DEMO_POSTS_NEWS.filter((_, i) => i >= 0 && i < 7);
//

const getArticles = () => fetch(`${BACKEND_URL}/articles`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});

const getCategories = () => fetch(`${BACKEND_URL}/categories`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
});


const PageHomeDemo6 = () => {
  const [hidden,setHidden] = useState(false)
  const [show,setShow] = useState(true)
  const [live, setlive] = useState("")
  const [category, setCategory] = useState("")
  const [subcategory, setSubCategory] = useState("")
  const [categoryid, setCategoryId] = useState(0)
  const [subcategoryid, setSubCategoryId] = useState(0)
  const [data,setData] = useState([])
  const [loading,setLoading]= useState(false)
  // const [subcategories,setSubCategories] = useState([])
  const [categories,setCategories] = useState([])
  const[extended, setExtended] = useState(false)
  const[option, setOption] = useState("Rozwiń")

  useEffect(() => {
    setLoading(true)
    loading == true && cogoToast.loading("Trwa ładowanie artykułów...")
    // setTimeout(() => {
      Promise.allSettled([getArticles(), getCategories()])
        .then(async (response) => {
          const jsonResponse = response.map(res => res.value.json());
          return Promise.allSettled(jsonResponse);
          // return [await daysRes.value.json(), await trainingsRes.value.json(), await plansRes.value.json()];
        }).then(([articlesData, categoriesData ]) => {
          if (!articlesData.value.hasOwnProperty("error")) {
            setData(articlesData.value);
            setLoading(false)
          }
          if (!categoriesData.value.hasOwnProperty("error")) {
            setLoading(false)
            setCategories(categoriesData.value);
          }
        }).catch(error => {
          console.error("error: ", error)
        })
  }, [])

  useEffect(() => {
      fetch(`${BACKEND_URL}/lives`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setlive(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);


  return (
    <div className="nc-PageHomeDemo6">
        <div>
        <div className="mobile-hidden"><WidgetCategoriesSecond posts={data} categories={categories} /></div>
        </div>
        {/* <div className="box-main grid-container hr container">
        <SectionMagazine1 posts={data.reverse()} />
        </div> */}
        {/* <hr className="desktop-line" style={{padding:"0.5px", marginTop:"20px", backgroundColor:"black"}}></hr> */}
        <div className="container mobile-header">
        <hr className="mobile-line" style={{padding:"2px", left:'18%', width:"82%", top:"52%",position:"absolute", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
        <HeaderFilter heading="Nysa i okolice"/>
        <hr className="mobile-line" style={{padding:"2px", marginTop:"20px", backgroundColor:"#ac8502"}}></hr>
        </div>
        <div className="box-main grid-container hr container">
          <div className="box-width-section">
          <div className="box-width grid-item w-full">
            <SectionMagazineFirst subcategory_id={subcategoryid} category_id={5} heading="Polecane" category="Gospodarka" posts={data}/></div>
              {/* <hr className="divider"></hr> */}
              {/* <div className="box-width grid-item w-full">
                <WidgetCategoriesFirst posts={data} categories={categories} />
              </div> */}
          </div>
              <div className="half-box">
              <WidgetHeaderCategories live={live} posts={data} categories={categories} />
                <WidgetVideo height={300} width={350} posts={data} categories={categories} />
              </div>
            </div>
            <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
        <div className="kitchen-box" style={{display:"flex",width:"100%", backgroundColor:"#f0efec", alignItems:"center", gap:"35px"}}>
        <div className="container" style={{paddingBottom:"2rem"}}>
            <SectionMagazineSecond5 subcategory_id={5} category_id={5} heading="Aktualności z regionu" posts={data} />
            </div>
        </div>
        <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
      {/* </div> */}
      <div className="box-main hr container">
      <div className="box-width-section">
            <div className="box-width">
              <SectionMagazineFirstSpecial subcategory_id={subcategoryid} category_id={categoryid} heading="Polecane" category="Sport" posts={data}/></div>
              {/* <hr className="divider"></hr> */}
              {/* <div className="box-width"><WidgetCategoriesThird posts={data} categories={categories} />
              </div> */}
              </div>
              <div className="half-box">
                {/* <WidgetVideo height={300} width={350} posts={data} categories={categories} /> */}
                </div>
              </div>
              <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
      <div className="relative overflow-hidden">
        <div className="relative">
          <div style={{backgroundColor: "rgb(240, 239, 236)"}}>
          <div className="container" style={{paddingBottom:"30px"}}>
              <SectionMagazine4 subcategory_id={subcategoryid} category_id={categoryid} heading="Wydarzenia dnia" category="Gospodarka" posts={data} />
            </div>
          </div>
          <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
          <div className="kitchen-box container">
              <div className="half-box"><SectionMagazine8/></div>
              <div className="half-box"><WidgetCategories posts={data} categories={categories} /></div>
          </div>
          <div style={{marginBottom:"2rem"}}></div>
          <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
          <a href="https://www.facebook.com/people/Optyk-Szczepanik/100051593446346/"><AdvertisingProvider>
          <div>
    <div className={extended === false ? "image": "slide-switch"}>
    <div style={{display:'flex',justifyContent:"end", paddingRight:"35px", gap:"8px", paddingTop:"10%", alignItems:"center"}}>
    </div>
    </div>
  </div>
          </AdvertisingProvider>
          </a>
          <hr className="desktop-line" style={{padding:"0.5px", marginTop:"0px", height:"0px", backgroundColor:"black"}}></hr>
          <SectionMagazineSecond4 className="container" subcategory_id={2} category_id={1}  heading="Polityka na świecie" posts={data} />
          <div style={{marginBottom:"2rem"}}></div>
        </div>
        {/* <div style={{paddingBottom:"2rem", paddingTop:'2rem', backgroundColor:'black'}} className="dark dark:bg-black dark:bg-opacity-20 text-neutral-100">
            <div className="container">
              <SectionGridPosts
                 headingIsCenter
                  postCardName="card10V2"
                  heading="Wideo"
                  subHeading="Artykuły wideo"
                  posts={data
                    .reverse()
                    .slice(data.length - 6, data.length)
                    .filter((article) => article.type == "wideo")}
                  gridClass="sm:grid-cols-2 lg:grid-cols-3"/>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default PageHomeDemo6;
