import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";

const WidgetCategoriesSecond = ({
  className = "bg-neutral-100",
  categories,
  live,
  posts,
}) => {

const data = posts.filter((post) => post.section_id == 5)
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        <div style={{paddingTop:"1%", justifyContent:'space-between'}} className="flex container column-box divide-y divide-neutral-200 dark:divide-neutral-700">
          {data
          .slice(data.length - 4, data.length)
          .reverse()
          .map((post) => {
            return (
            <CardCategory1
              className="p-4 dark:hover:bg-neutral-700 hover:bg-neutral-200"
              key={post.id}
              id={post.id}
              video={post?.video}
              type={post?.type}
              image={post?.image}
              category={post?.name}
              subcategory={post.title.toLowerCase()}
              category_id={post?.category_id}
              subcategory_id={post?.subcategory_id}
              name={post?.article_title}
              size="normal"
            />
          )
          })}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategoriesSecond;
