import React, { useEffect, useRef, useState } from "react";
import pdf from "../gazeta-grudzień/ResPublica grudzien (1).pdf"

const PageGazetaDecember = () => {
  useEffect(() => {
    window.location.href = pdf
  })
};

export default PageGazetaDecember
