import React, { useEffect, useRef, useState } from "react";
import pdf from "../gazeta-pażdziernik-first/respublica pazdziernik na fb.pdf"

const PageGazetaOctoberSecond = () => {
  useEffect(() => {
    window.location.href = pdf
  })
};

export default PageGazetaOctoberSecond
