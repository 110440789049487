import React, { useEffect, useRef, useState } from "react";
import pdf from "../gazeta-listopad/Respublica listopad (1).pdf"

const PageGazetaNovember = () => {
  useEffect(() => {
    window.location.href = pdf
  })
};

export default PageGazetaNovember
