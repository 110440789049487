import CardCategory1 from "components/CardCategory1/CardCategory1";
import LikeSaveBtns from "components/LikeSaveBtns"
import Link from "components/Link"
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import WidgetTags from "components/WidgetTags/WidgetTags"
import { BACKEND_URL } from "constants"
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import ViewerScreenContainer from "ViewerScreenContainer"

const WidgetHeaderCategories = ({
  className = "dark:bg-neutral-800",
  categories,
  live,
  posts,
}) => {
  const [video,setVideo] = useState(false)

  const filtered = posts.filter((post) => post.section_id == 6)
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        {/* <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700"> */}
        <button style={{backgroundColor:'red', fontSize:"20px", color:"white", width:"46%", border:"none", padding:"3px 3px 3px 3px"}}>Bądź na bieżąco</button>
          {/* {video === false && <button onClick={() => {
            setVideo(true)}}>Zobacz live</button>}
            {video === true && <button onClick={() => {
            setVideo(false)}}>Wyłącz live</button>} */}
          {video === true && <ViewerScreenContainer meetingId={live[0]?.url.slice(38,58)} />}
          {filtered
          .slice(filtered.length - 5, filtered.length)
          .reverse()
          .map((post) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={post.id}
              id={post.id}
              video={post?.video}
              type={post?.type}
              image={post?.image}
              category={post?.name}
              subcategory={post?.title.toLowerCase()}
              category_id={post?.category_id}
              subcategory_id={post?.subcategory_id}
              name={post?.article_title}
              size="normal"
            />
          ))}
           {/* <div className={`nc-WidgetTags rounded-3xl overflow-hidden `}>
      <div className="flex flex-wrap p-4 xl:p-5">
        {subcategories.map((tag) => (
          <div>
            <Link id={tag.id} key={tag.id} onClick={(e) => setId(e.target.id) }
      className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900mr-2 mb-2`}
      href={tag.href}
    >
      {`${tag.title}`}
      {!hideCount && (
        <span className="text-xs font-normal">{tag.count}</span>
      )}
    </Link>
          </div>
        ))}
      </div>
    </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default WidgetHeaderCategories;