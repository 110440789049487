import React, { useEffect, useRef, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { useParams } from "react-router-dom"
import { BACKEND_URL } from "constants"
import SectionSliderPosts from "components/Sections/SectionSliderPosts"

const PageSingle = () => {
  const params = useParams()
  const [data,setData] = useState([])
  const [articles,setArticles] = useState([])
  const initialized = useRef(false);
  const text = data[0]?.long_description

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/article/${+params.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);


  useEffect(() => {
      fetch(`${BACKEND_URL}/articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setArticles(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);

  return (
    <>
     {/* <Layout data={data}> */}
      <div style={{display:"flex", flexFlow:"column", alignItems:"baseline", justifyContent:"baseline"}} className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container single-responsive">
          <div>
            <SingleHeader data={data} />
          </div>
        </header>
        <div className="container single-responsive">
           <p><pre style={{maxWidth:"1371px", whiteSpace:"pre-wrap", fontSize:"18px", fontFamily:'Roboto', textAlign:'justify'}}>{text}</pre></p>
          <div style={{display:"flex", gap: '36px', flexFlow:"column", alignItems:"flex-start"}}>
          {data[0]?.image == undefined && <span></span>}
         {data[0]?.type == "image" && data[0]?.image != undefined &&
          <NcImage
          alt="image"
          containerClassName=""
          className="w-full image-resp"
          src={data[0]?.image}
          width={1000}
          height={900}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />}
        {data[0]?.type == "wideo" && <video src={data[0]?.video} poster={data[0]?.image} controls width={1000} height={900} />}
          </div>
        </div>

        {/* FEATURED IMAGE */}
        {/* <div> */}
        <div className="container">
        <SectionSliderPosts
          className="py-16"
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={articles.slice(17,24)}
        />
        </div>
        {/* </div> */}
      </div>
      </>
  );
};

export default PageSingle;
