import React, { FC } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "components/Link";
import Image from "components/Image";
import CategoryBadgeListCard9 from "components/CategoryBadgeList/CategoryBadgeListCard9"

const Card9 = ({
  className = "h-full",
  ratio = "aspect-w-3 aspect-h-3 sm:aspect-h-3",
  post,
  hoverClass = "",
  imgClassName = ""
}) => {
  const { image, type, video, category_id, subcategory_id } =
    post;

  const renderMeta = () => {
    return (
      <div 
      // className="flex items-center text-xs text-neutral-300"
      >
        <div style={{width:"200px"}} className="block">
          <div style={{marginLeft:"5px"}} className="mb-3"></div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-Card9 relative flex flex-col group overflow-hidden z-0 ${hoverClass} ${className}`}
    >
      {/* <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">
        <PostCardLikeAndComment className="relative" />
        <PostCardSaveAction hidenReadingTime className="relative" />
      </div> */}
      {/* <div className={`flex items-start relative w-full ${ratio}`}></div> */}
      <Link style={{color:"black"}} href={`/${post?.title.toLowerCase()}/${post?.name.toLowerCase()}/${category_id}/${subcategory_id}/artykuł/${post.id}`}>
      {image == undefined && <span></span>}
         {type == "image" && image != undefined &&
          <Image
          style={{height:'auto'}}
          fill
          alt=""
          className={`w-full h-full ${imgClassName}`}
          src={image}
          sizes="(max-width: 600px) 480px, 500px"
        />}
        {type == "wideo" && <video src={post?.video} poster={image} controls width={1000} height={900} />}
        </Link>
    </div>
  );
};

export default Card9;
